/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


* {
  box-sizing: border-box;
}

$max-img-width: 1200px;
$max-img-height: 800px;

img {
  max-width: 100%;
  vertical-align: top;
}

.gallery {
  display: flex;
    margin: 10px auto;
    max-width: $max-img-width;
    position: relative;
    padding-top: $max-img-height/$max-img-width * 100%;
    
    @media screen and (min-width: $max-img-width){
      padding-top: $max-img-height;
    }
  
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
   &__thumb {
    padding-top: 6px;
    margin: 6px;
    display: block;
  }
  
  &__selector {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    
    &:checked {
      + a .gallery__img {
        opacity: 1;
      }
      ~ .gallery__thumb > img {
        box-shadow: 0 0 0 3px #0be2f6;;
      }
    }
  }
  
 
}